<template>
  <div>
    <v-form ref="loginForm">
      <v-container fluid>
        <v-row class="justify-center">
            <v-col class="offset-md-1 pl-5" cols="2">
                <h2>Login</h2>
            </v-col>
        </v-row>
        <v-row class="justify-center">
          <v-col cols="2" class="pb-2 pt-0 mt-0">
            <v-text-field v-model="email" label="Email"></v-text-field>
          </v-col>
        </v-row>
        <v-row class="justify-center">
          <v-col cols="2" class="pt-0 pb-0 mb-0">
            <v-text-field type="password" v-model="password" label="Password" @keyup.enter="submit"></v-text-field>
          </v-col>
        </v-row>
        <v-row class="justify-center">
          <v-col cols="2" class="pt-0 mt-0 offset-xl-1">
            <router-link :to="'/passwordResetRequest'">Forgot Password</router-link>
          </v-col>
        </v-row>
        <v-row class="justify-center">
          <v-col class="offset-md-1" cols="2">
            <v-btn color="primary" :disabled="isLoggedIn || !canSubmit" @click="submit">
                Submit
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import router from '@/router';

export default {
  data() {
    return {
      email: '',
      password: '',
    };
  },
  computed: {
    ...mapState('account', ['isLoggedIn']),
    ...mapGetters('account', ['isLoggedIn']),
    canSubmit() {
      return this.email !== '' && this.password !== '';
    },
  },
  methods: {
    ...mapActions('account', ['login']),
    async submit() {
      if (this.email && this.password) {
        const loginRequest = {
          email: this.email,
          password: this.password,
        };
        await this.login(loginRequest);
      }
    },
  },
  mounted() {
    if (this.isLoggedIn) {
      router.push('gate');
    }
  },
};
</script>
